import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from 'vanilla-cookieconsent';
import {RepositoryFactory} from "./repository/RepositoryFactory";
const ConsentRepository = RepositoryFactory.get('consent');

CookieConsent.run({
    disablePageInteraction: false,

    cookie: {
        name: 'noezon_cc',
    },

    guiOptions: {
        consentModal: {
            layout: 'box wide',
            position: 'middle center'
        },
        preferencesModal: {
            layout: 'box'
        }
    },

    onFirstConsent: ({cookie}) => {
        ConsentRepository.save('cookie-law', cookie.categories);

        dataLayer.push({
            'event': 'cookie_consent_update'
        });
    },

    onConsent: () => {
        gtag('consent', 'update', {
            'ad_personalization': CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
            'ad_storage': CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
            'ad_user_data': CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
            'analytics_storage': CookieConsent.acceptedCategory('analytics') ? 'granted' : 'denied',
            'functionality_storage': CookieConsent.acceptedCategory('personalization') ? 'granted' : 'denied',
            'personalization_storage': CookieConsent.acceptedCategory('personalization') ? 'granted' : 'denied',
            'security_storage': 'granted',
        });

        dataLayer.push({
            'event': 'cookie_consent_update'
        });
    },

    onChange: ({cookie}) => {
        ConsentRepository.save('cookie-law', cookie.categories);

        gtag('consent', 'update', {
            'ad_personalization': CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
            'ad_storage': CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
            'ad_user_data': CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
            'analytics_storage': CookieConsent.acceptedCategory('analytics') ? 'granted' : 'denied',
            'functionality_storage': CookieConsent.acceptedCategory('personalization') ? 'granted' : 'denied',
            'personalization_storage': CookieConsent.acceptedCategory('personalization') ? 'granted' : 'denied',
            'security_storage': 'granted',
        });

        dataLayer.push({
            'event': 'cookie_consent_update'
        });
    },

    categories: {
        necessary: {
            readOnly: true,
            enabled: true
        },
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^(_ga|_gid)/
                    }
                ]
            }
        },
        personalization: {},
        marketing: {},
    },

    language: {
        default: 'sk',

        translations: {
            'sk': require('./locales/consent.sk.json'),
            'cs': require('./locales/consent.cs.json'),
        }
    }
});

CookieConsent.setLanguage(window.defaults.locale.current);

const elDesktop = document.getElementById("settings-cookies");
const elMobile = document.getElementById("settings-cookies-m");

if(elDesktop) {
    elDesktop.onclick = function() {
        CookieConsent.show(true);
    };
}

if(elMobile) {
    elMobile.onclick = function() {
        CookieConsent.show(true);
    };
}